import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

import "../scss/contact.scss"

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <h1 className="page_title">Contact</h1>

      <form
        className="contact_form"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        name="contact"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />

        <div className="first_last_names">
          <label id="first_name">
            <span className="form_label">First Name*</span>
            <input
              className="form_input"
              type="text"
              name="first_name"
              id="first_name"
              required
            />
          </label>
          <label id="last_name">
            <span className="form_label">Last Name*</span>
            <input
              className="form_input"
              type="text"
              name="last_name"
              id="last_name"
              required
            />
          </label>
        </div>
        <label>
          <span className="form_label">Email*</span>
          <input
            className="form_input"
            type="email"
            name="email"
            id="email"
            required
          />
        </label>
        <label>
          <span className="form_label">Subject</span>
          <input
            className="form_input"
            type="text"
            name="subject"
            id="subject"
          />
        </label>
        <label>
          <span className="form_label">Message</span>
          <textarea
            className="form_textarea"
            name="message"
            id="message"
            rows="5"
          />
        </label>

        <div className="buttons">
          <button className="send" type="submit">
            Send
          </button>
          <input className="clear" type="reset" value="Clear" />
        </div>
      </form>
      {/*<Link to="/page-2/">Go to page 2</Link>*/}
    </Layout>
  )
}

export default AboutPage
